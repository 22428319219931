<template>
  <DefaultLayout>
    <MapEmbed />

    <!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
-->

    <div class="bg-white">
      <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div class="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div class="space-y-5 sm:space-y-4">
            <h2 class="text-3xl font-['Poly'] font-extrabold tracking-tight sm:text-4xl">Tobago Island</h2>
            <p class="text-xl text-gray-500">
              Tobago is a small tropical island with amazing beaches, easily accessible rainforest and carefree culture.
              Vacationers can choose from a diverse range of activities, including snorkeling, diving, fishing, surfing,
              paddleboarding, hiking, biking, golfing, horseback riding, bird watching, and dancing to calypso and steel
              pan music. From Top O' Tobago, you can drive to more than ten different beaches in less than fifteen
              minutes.
            </p>
          </div>
          <div class="lg:col-span-2">
            <ul role="list" class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
              <li v-for="item in items" :key="item.title">
                <div class="space-y-4">
                  <div class="aspect-w-3 aspect-h-2">
                    <img class="object-cover shadow-lg rounded-lg" :src="item.imageUrl" alt="" />
                  </div>
                  <div class="text-lg leading-6 font-medium space-y-1">
                    <!-- <h3>{{ person.name }}</h3> -->
                    <p class="text-teal-600">{{ item.title }}</p>
                  </div>
                  <div class="text-md">
                    <p class="text-gray-500">{{ item.description }}</p>
                  </div>

                  <!-- <ul role="list" class="flex space-x-5">
                    <li>
                      <a
                        :href="person.twitterUrl"
                        class="text-gray-400 hover:text-gray-500"
                      >
                        <span class="sr-only">Twitter</span>
                        <svg
                          class="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"
                          />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        :href="person.linkedinUrl"
                        class="text-gray-400 hover:text-gray-500"
                      >
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul> -->
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="flex justify-center mt-4">
          <ImageGallery :imageUrls="galleryImageUrls" />
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script setup>
import MapEmbed from "@/components/MapEmbed.vue";
import ImageGallery from "@/components/ImageGallery.vue";

const items = [
  {
    title: "Tropical temperatures with cooling sea breezes",
    imageUrl: "https://res.cloudinary.com/millsit/image/upload/v1648412627/TOT/island/feature_hiking_mot_mot_trail_tqhaqk.jpg",
    description:
      "The average daytime temperature is 29°C (83°F) year round, with a 'Drier' Season between December and May, and a 'Wetter' Season between June and November.",
  },
  {
    title: "Both marine and jungle experiences",
    imageUrl: "https://res.cloudinary.com/millsit/image/upload/v1648412627/TOT/island/feature_hiking_trail_uqosrx.jpg",
    description:
      "Tobago has some of the best diving sites in the Caribbean, numerous idyllic beaches and a large area of rainforest which claims to be the oldest protected forest in the Western world. It is very approachable since it is relatively small.",
  },
  {
    title: "The best of the 'developed' and 'developing' world",
    imageUrl: "https://res.cloudinary.com/millsit/image/upload/v1648412627/TOT/island/feature_kids_beach_blfifz.jpg",
    description:
      "Tobago is part of the Republic of Trinidad and Tobago, one of the wealthiest countries in the Caribbean because of the petroleum based industries located in Trinidad. The economy of Tobago is primarily dependent on tourism and government spending.Tobago has some of the best diving sites in the Caribbean, numerous idyllic beaches and a large area of rainforest which claims to be the oldest protected forest in the Western world. It is very approachable since it is relatively small.",
  },
  {
    title: "Cultural diversity across regions and times",
    imageUrl: "https://res.cloudinary.com/millsit/image/upload/v1648412627/TOT/island/feature_diversity_vhgqqa.jpg",
    description:
      "The population of Tobago is small (64,000) and primarily of African descent with a growing proportion of Trinidadians of East Indian descent and most recently Europeans.",
  },
  {
    title: "Everything an independent traveler could want",
    imageUrl: "https://res.cloudinary.com/millsit/image/upload/v1648412627/TOT/island/feature_beach_bar_vhlick.jpg",
    description:
      "There are several daily flights and a daily ferry service from Trinidad; a variety of accommodations and transportation options and numerous certified tours and tour guides.",
  },
];

const galleryImageUrls = [
  "https://res.cloudinary.com/millsit/image/upload/ar_16:9,c_fill,e_sharpen,g_auto,h_400,w_770/v1690303458/TOT/island/Buccoo_sn2obc.jpg",
  "https://res.cloudinary.com/millsit/image/upload/ar_16:9,c_fill,e_sharpen,g_auto,h_400,w_770/v1690303458/TOT/island/Beach_sg4zby.jpg",
  "https://res.cloudinary.com/millsit/image/upload/ar_16:9,c_fill,e_sharpen,g_auto,h_400,w_770/v1690303457/TOT/island/Rainforest_sqlyrv.jpg",
  "https://res.cloudinary.com/millsit/image/upload/ar_16:9,c_fill,e_sharpen,g_auto,h_400,w_770/v1690303457/TOT/island/Charlotteville_pcwyye.jpg",
  "https://res.cloudinary.com/millsit/image/upload/ar_16:9,c_fill,e_sharpen,g_auto,h_400,w_770/v1690303457/TOT/island/Pigeon_Point_dsi9m9.jpg",
]
</script>

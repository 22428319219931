import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-923765ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-center items-center" }
const _hoisted_2 = { class: "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4" }
const _hoisted_3 = { class: "image-container max-h-60 max-w-full rounded-lg" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageUrls, (imageUrl, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "flex items-center justify-center"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: imageUrl,
              alt: "",
              class: "image-content"
            }, null, 8, _hoisted_4)
          ])
        ]))
      }), 128))
    ])
  ]))
}
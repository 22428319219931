import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import IndexView from "../views/index.vue";
import PrivateGuestForm from "../views/Private.vue";
import TobagoIsland from "../views/Tobagoisland.vue";
import Topotobago from "../views/Topotobago.vue";
import AccommodationView from "../views/accommodation/index.vue";
import MainHouse from "../views/accommodation/mainhouse.vue";
import Cabanas from "../views/accommodation/cabanas.vue";
import Birds from "../views/Birds.vue";
import Book from "../views/Book.vue";
import Contact from "../views/Contact.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: IndexView,
  },
  {
    path: "/private/guestform/:id",
    name: "privateGuestForm",
    component: PrivateGuestForm,
  },
  {
    path: "/tobagoisland",
    name: "TobagoIsland",
    component: TobagoIsland,
  },
  {
    path: "/topotobago",
    name: "Topotobago",
    component: Topotobago,
  },
  {
    path: "/accommodation",
    name: "Accommodation",
    component: AccommodationView,
  },
  {
    path: "/accommodation/mainhouse",
    name: "MainHouse",
    component: MainHouse,
  },
  {
    path: "/accommodation/cabanas",
    name: "Cabanas",
    component: Cabanas,
  },
  {
    path: "/birds",
    name: "Birds",
    component: Birds,
  },
  {
    path: "/book",
    name: "Book",
    component: Book,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

import { ref, onMounted } from 'vue';
import { Pricing } from "@shared/types";
import { getPricingByRoomType } from "@/lib/rooms";
import moment from "moment";

export function useRoomPrice(room: any) {
  const updatedRoom = ref({ ...room });

  onMounted(async () => {
    const roomPrices: Pricing[] = await getPricingByRoomType(updatedRoom.value.type);
    const pricingIndex: number = updatedRoom.value.details.findIndex((item: { name: string; }) => item.name === 'Pricing');

    roomPrices.forEach(({ priceUSD, priceTTD, startDate, endDate }) => {
      const formattedStartDate = moment(startDate).format('MMM D, y');
      const formattedEndDate = moment(endDate).format('MMM D, y');
      const desc = `US$${priceUSD} (TT$ ${priceTTD}) / night from ${formattedStartDate} to ${formattedEndDate}`;
      updatedRoom.value.details[pricingIndex].items.push(desc);
    });
  });

  return updatedRoom;
}

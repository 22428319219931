import { SanityDocumentStub, createClient } from "@sanity/client";
import type {
  Booking,
  Review,
  ReviewLocation,
  Guest,
  BookingAndGuest,
} from "../../../shared/src/types";

import { SANITY_SECRET, SANITY_DATASET } from "./config";
import {Pricing} from "../../../shared/src/types";
import {addYears, format as dateFormat} from "date-fns";

export const RoomLabelMap: { [k: string]: string } = {
  cabana1: "Cabana #1",
  cabana2: "Cabana #2",
  cabana3: "Cabana #3",
  mainhouse: "Main House",
};

export const client = createClient({
  projectId: "uaukqoxm",
  dataset: SANITY_DATASET,
  apiVersion: "2022-04-14", // use current UTC date - see "specifying API version"!
  token: SANITY_SECRET, // or leave blank for unauthenticated usage
  useCdn: false, // `false` if you want to ensure fresh data
});

/**
 * Get bookings from Sanity, within a period any overlapping
 * Bookings whose:
 * 1. booking.start >= checkin && booking.start <= checkout  // booking starts in period
 * 2. booking.end >= checkin && booking.end <= checkout  // booking ends in period
 * 3. booking.start < checkin && booking.end > checkout // period is within booking
 * @param checkin
 * @param checkout
 * @returns {Array<Booking>} all bookings between
 */
export const getBookings = async ({
  checkin,
  checkout,
}: {
  checkin: string;
  checkout: string;
}): Promise<Array<Booking> | Array<undefined>> => {
  const overlapQueryArr = [
    `(checkin >= "${checkin}" && checkin <= "${checkout}")`,
    `(checkout >= "${checkin}" && checkout <= "${checkout}")`,
    `(checkin < "${checkin}" && checkout > "${checkout}")`,
  ];
  const query = `*[_type == "booking"  && !(_id in path("drafts.**")) && (${overlapQueryArr.join(" || ")})]`;
  // console.log({SANITY_SECRET , SANITY_DATASET});
  let bookings: Array<Booking> | Array<undefined> = [];
  try {
    const resp = await client.fetch(query);
    bookings = resp;
  } catch (e) {
    console.log(e);
  }
  return bookings;
};


/**
 * Returns reviews from Sanity
 * @param location location on website
 */
export const getReviewByLocation = async (location: ReviewLocation = "homepage") => {
  const query = `*[_type == "review" && location == "${location}"]`;
  // console.log(query);
  let reviews: Array<Review | undefined> = [];
  try {
    const resp = await client.fetch(query);
    reviews = resp;
  } catch (e) {
    console.log(e);
  }
  return reviews;
};

/**
 * Add a new booking
 * @param booking booking object
 */
export const addBooking = async (booking: Booking, guest: Guest): Promise<BookingAndGuest> => {
  //upsert the guest
  try {
    const dbGuest = await upsertGuestByEmail(guest);
    if (!dbGuest) throw Error("Could not get/create guest information");
    // prep the booking object
    let bookingToAdd: SanityDocumentStub<Booking> = {
      ...booking,
      _type: "booking",
      guest: { _type: "reference", _ref: dbGuest._id },
      status: "Pending",
      search:`${dbGuest.firstName || ''} ${dbGuest.lastName || ''} ${dbGuest.email || ''} ${dbGuest.phone || ''}`
      // add _key for room details (Sanity requirement for arrays) now done client side
      // bookingRef: nanoid(), client side
      // bookingDate: moment(),  client side

      // roomDetails: booking.roomDetails?.map((rd) => ({ ...rd, _key: nanoid() })),
    };
    let bookingInserted = await client.create<Booking>(bookingToAdd);
    if (!bookingInserted) throw Error("Could not create booking");

    return { ...bookingInserted, guest: dbGuest };
    
  } catch (e) {
    console.log(e);
    throw e;
  }
};

const findGuestbyEmail = async (email: string): Promise<Guest | void> => {
  const query = '*[_type == "guest" && email == $email][0]';
  const params = { email };
  return client.fetch(query, params).then((guests) => {
    // console.log("Bikes with more than one seat:");
    return guests;
  });
};

/**
 * Adds or updates guest info using email as unique key
 * @param guest
 */
const upsertGuestByEmail = async (guest: Guest): Promise<Guest | void> => {
  if (!guest.email) throw Error("Need email for guest");
  try {
    let existingGuest = await findGuestbyEmail(guest.email);
    // we found a guest with that email, so update the contact info and return it
    if (existingGuest) {
      let updatedGuest = await client.createOrReplace<Guest>({...existingGuest, ...guest});
      return updatedGuest;
    } else {
      //no guest found, create and send it
      return await client.create<Guest>({ ...guest, _type: "guest" });
    }
  } catch (e) {
    console.error(e);
    return;
  }
};

/**
 * Returns booking info to populrate the guest form for print
 * @param location location on website
 */
export const getBookingForGuestForm = async (id: string) => {
  const query = `*[_type == "booking" && _id == "${id}"]{..., guest->}`;
  // console.log(query);
  let booking: Partial<Booking> = {};
  try {
    const resp = await client.fetch(query);
    booking = resp;
  } catch (e) {
    console.log(e);
  }
  return booking;
};

/**
 * Get pricing for rooms based on room type and within a period
 * @param roomType string
 * @return {Pricing[]}
 */
export const getRoomPricesWithinSpecificPeriod = async (roomType: string): Promise<Array<Pricing | undefined>> => {
  const currentDate = dateFormat(new Date(), "yyyy-MM-dd");
  const nextYearDate = dateFormat(addYears(new Date(), 1), "yyyy-MM-dd");

  const overlapQueryArr = [
    `(startDate <= "${currentDate}" && endDate >= "${currentDate}")`,
    `(startDate <= "${nextYearDate}" && endDate >= "${nextYearDate}")`,
    `(startDate >= "${currentDate}" && endDate <= "${nextYearDate}")`,
  ];

  const query = `*[_type == "pricing" && roomType == "${roomType}" && (${overlapQueryArr.join(" || ")})] | order(startDate asc)`;
  let roomPrices: Array<Pricing | undefined> = [];
  try {
    roomPrices = await client.fetch(query);
  } catch (e) {
    console.log(e);
  }

  return roomPrices;
};
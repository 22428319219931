<template>
  <Carousel :autoplay="3000" :wrap-around="true">
    <Slide v-for="slide in 2" :key="slide">
      <div>
        <img
          class="h-full w-full object-cover"
          :src="images[slide - 1]"
          alt="View from top"
        />
        <div class="absolute inset-0 bg-gray-400 mix-blend-multiply" />
      </div>
    </Slide>
    <!-- <template #addons>
      <Pagination />
    </template> -->
  </Carousel>
</template>

<script setup>
import { Carousel, Pagination, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";
const images = [
  "https://res.cloudinary.com/millsit/image/upload/v1648245198/TOT/heromain_jmzzkk.jpg",
  "https://res.cloudinary.com/millsit/image/upload/v1648329084/TOT/heromain2_rcjjyq.jpg",
  "https://res.cloudinary.com/millsit/image/upload/v1648329084/TOT/heromain3_b3j1ov.jpg",
];

// export default {
//   components: {
//     Carousel,
//     Pagination,
//     Slide,
//   },
//   setup() {
//     return {
//       images: [
//         "https://res.cloudinary.com/millsit/image/upload/v1648245198/TOT/heromain_jmzzkk.jpg",
//         "https://res.cloudinary.com/millsit/image/upload/v1648329084/TOT/heromain2_rcjjyq.jpg",
//         "https://res.cloudinary.com/millsit/image/upload/v1648329084/TOT/heromain3_b3j1ov.jpg",
//       ],
//     };
//   },
// };
</script>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style/index.css";

import { useSanityClient } from 'vue-sanity'


const app = createApp(App);

import DefaultLayout from "./layouts/default.vue";
app.component("DefaultLayout", DefaultLayout);

import EmptyLayout from "./layouts/empty.vue";
app.component("EmptyLayout", EmptyLayout);

import HeaderDefault from "./components/HeaderDefault.vue";
app.component("HeaderDefault", HeaderDefault);

import Button from "./components/elements/Button.vue";
app.component("Button", Button);

import Banner from "./components/elements/Banner.vue";
app.component("Banner", Banner);

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
app.component('VueDatepicker', Datepicker);

app.use(store).use(router).mount("#app");

<template>
  <div>
    <dl class="mt-10 space-y-4 text-base leading-7 text-gray-600">
      <div class="flex gap-x-4">
        <dt class="flex-none">
          <span class="sr-only">Telephone</span>
          <img
            src="https://res.cloudinary.com/millsit/image/upload/v1679790329/TOT/contact/whatsapp_icon_yfjazf.png"
            class="object-scale-down h-12 w-12"
          />
        </dt>
        <dd>Have questions? Contact us on WhatsApp<br /><a href="https://wa.me/18686870121" target="_blank">(+1) 868-687-0121</a></dd>
      </div>
      <!-- <div class="flex gap-x-4">
          <dt class="flex-none">
            <span class="sr-only">Telephone</span>
            <PhoneIcon class="h-7 w-6 text-gray-400" aria-hidden="true" />
          </dt>
          <dd><a class="hover:text-gray-900" href="tel:+1 (555) 234-5678">+1 (555) 234-5678</a></dd>
        </div>
        <div class="flex gap-x-4">
          <dt class="flex-none">
            <span class="sr-only">Telephone</span>
            <EnvelopeIcon class="h-7 w-6 text-gray-400" aria-hidden="true" />
          </dt>
          <dd><a class="hover:text-gray-900" href="mailto:hello@example.com">hello@example.com</a></dd>
        </div> -->
    </dl>
  </div>
</template>
<script setup lang="ts"></script>

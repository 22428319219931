export const TotServices = [
    {
      name: "Top O' Tobago Services Included",
      items: [
        "Airport Pickup",
        "Daily onsite management",
        "Welcome Groceries Stocked (You pay cost of groceries)",
        "Housekeeping every 2 days",
        "High-speed WiFi throughout",
        "Choice of Natural Ventilation or Air Conditioning",
        "Laundry Facilities",
        "Snorkel and Beach Equipment available for use",
      ],
    },
    {
      name: "Requirements",
      items: [
        "A minimum two night stay is required",
        "A 20% deposit on your entire stay is necessary to confirm your reservation",
        "Check in time: 3pm; Check out time: 11am; with flexibility applied by onsite manager.",
      ],
    },
    {
      name: "Pricing",
      items: [],
    },
    {
      name: "Discounts",
      items: [
        "A 10% discount is available to repeat guests, and a 5% discount to T&T residents. This discount is reflected in the TTD pricing. Discounts are not additive. They are applied by the manager upon payment of the final balance."
      ],
    },
    {
      name: "Cancellations",
      items: [
        "Cancellations more than 60 days before check in or within 2 days of booking: full refund of deposit.",
        "Cancellations between 7 and 60 days before check in: refund of 50% of deposit.",
        "Cancellations less than 7 days: no refund.",
        "Alternatively, all deposits can be used towards a future stay at Top O' Tobago within one year of cancellation.",
      ],
    },
  ];
  
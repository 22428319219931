import type { Booking, RoomDetails, RoomTypes } from "./types";

export const allRooms: { type: RoomTypes; room: string; label: string }[] = [
  {
    type: "cabana",
    room: "cabana1",
    label: "Cabana #1",
  },
  {
    type: "cabana",
    room: "cabana2",
    label: "Cabana #2",
  },
  {
    type: "cabana",
    room: "cabana3",
    label: "Cabana #3",
  },
  {
    type: "mainhouse",
    room: "mainhouse",
    label: "Main house",
  },
];

export const getRoomTypeByName = (room: string): string => {
  return allRooms.find((a) => a.room === room)?.type || "xxxx";
};

export const getRoomLabelByName = (room: string): string => {
  return allRooms.find((a) => a.room === room)?.label || "xxxx";
};

/**
 * Return a nice list of rooms
 * @param roomDetails 
 */
export const getRoomListNice = (roomDetails: RoomDetails[]): Array<string> => {
  return roomDetails?.map((rd) => {
    return `${getRoomLabelByName(rd.room)}`;
  });
};

export const getRoomInitials = (room: string): string => {
  if(!room) return "";
  return room.charAt(0).toUpperCase() + (room.slice(-1) == "e" ? '' : room.slice(-1));
}

/**
 * BEcause we store only room name in Sanity cabana1, cabana2, etc. we need to map with a type
 * @param roomName
 */
export const mapRoomType = (roomName: string): RoomTypes | undefined => {
  return allRooms.find((r) => r.room == roomName)?.type;
};

export const roomExtras = {
  gorceries: {
    priceTTD: 200,
    priceUSD: 30,
  },
};

<template>
  <DefaultLayout>
    <HeaderDefault title="Top O' Tobago" />
    <div class="relative px-1 py-2 sm:px-4 sm:py-16 lg:py-12 lg:px-8">
      <p class="mt-6 max-w-lg mx-auto text-center text-xl text-gray-800 sm:max-w-5xl">
        Top O’ Tobago has been in operation since 1995. It is owned by Derek and Camille Baker. Camille was born in
        Trinidad, and, Derek and Camille reside in the main house at Top O’ Tobago from early November to the end of
        March each year. When not in Tobago, Derek and Camille reside at Kootenay Lake, British Columbia, Canada.
      </p>
    </div>
    <FeatureVideo />
    <BlogListCards :posts="posts" />
  </DefaultLayout>
</template>
<script setup>
import FeatureVideo from "@/components/FeatureVideo.vue";
import BlogListCards from "@/components/BlogListCards.vue";

const posts = [
  {
    title: "Beauty in many forms",
    href: "#",
    description: "Enjoy 360 degree views of coastlines and hillsides, flowers and forests, and original works of art.",
    imageUrl: "https://res.cloudinary.com/millsit/image/upload/v1648840296/TOT/topotobago/topo_beauty_te8exo.jpg",
  },
  {
    title: "World class beaches and hiking at your doorstep",
    href: "#",
    description:
      "It is a seven minute walk to Arnos Vale Bay, a great snorkeling beach, as well as to the start of the Mot Mot Trail, a well recommended hiking trail.",
    imageUrl: "https://res.cloudinary.com/millsit/image/upload/c_scale,w_770/c_crop,h_400,w_1085/v1684216285/TOT/topotobago/Arnos_Vale_Beach_kw2fkw.jpg",
    //"https://res.cloudinary.com/millsit/image/upload/v1648840296/TOT/topotobago/topo_beaches_rql0p1.jpg",
  },
  {
    title: "Easy, natural living",
    href: "#",
    description: "Residents move in and out seamlessly all day because indoor and outdoor spaces flow as one.",
    imageUrl: "https://res.cloudinary.com/millsit/image/upload/v1648840297/TOT/topotobago/topo_easyliving_ltx7yb.jpg",
  },
  {
    title: "Leaders in environmental care",
    href: "#",
    description:
      "At Top O’ Tobago we generate solar power, treat our drinking water, provide you the choice of natural ventilation or air-conditioning, re-use the water run-off from roofs and patios for agricultural purposes, and compost our organic waste.",
    imageUrl: "https://res.cloudinary.com/millsit/image/upload/v1677706580/TOT/topotobago/rooftop_solar_t9qz0v.jpg", //"https://res.cloudinary.com/millsit/image/upload/v1648840297/TOT/topotobago/topo_environment_xxm8zb.jpg",
  },
  {
    title: "Superb services",
    href: "#",
    description:
      "Six staff are there to help; Silja is our onsite manager; Susan, our head housekeeper and bookkeeper; Zambo, our chief landscaper and maintenance person, Kurtney, our gardener; and Derek and Camille do what else needs to be done, half the year onsite, and half the year from afar.",
    imageUrl: "https://res.cloudinary.com/millsit/image/upload/v1677706738/TOT/topotobago/staff_pic_2023_pw8h6l.jpg", //"https://res.cloudinary.com/millsit/image/upload/v1648840296/TOT/topotobago/topo_staff_tvytwg.jpg",
  },
];
</script>


import {defineComponent} from 'vue'

export default defineComponent({
  name: "ImageGallery",
  components: {
  },
  props: {
    imageUrls: {
      type: Array,
      required: true
    }
  }
})

<template>
    <SwitchGroup as="div" class="flex items-center">
      <Switch v-model="enabled" :class="[enabled ? 'bg-teal-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2']">
        <span aria-hidden="true" :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
      </Switch>
      <SwitchLabel as="span" class="ml-3 text-sm">
        <slot />
      </SwitchLabel>
    </SwitchGroup>
  </template>
  
  <script setup>
  import { ref, watch} from 'vue'
  import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
//   const { label } = defineProps(["label"]);
  const enabled = ref(false)
  const emit = defineEmits(["updated"]);
  watch(
    enabled,
    (v) => emit('updated', v)
  )
  </script>
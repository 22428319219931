<template>
<DefaultLayout>
    <HeaderDefault title="Birds" />
    <div class="relative bg-white pt-16 pb-16 overflow-hidden">
      <div class="relative">
        <div
          class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24"
        >
          <div
            class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0"
          >
            <div>
              <div class="mt-6 text-lg font-['Poly'] text-gray-500">
                <!-- <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">Stay on top of customer support</h2> -->
                <p>
                  No matter what your level of interest, knowledge or
                  experience, you are sure to enjoy the birds at Top O' Tobago.
                  Tobago offers some of the best bird watching in the Caribbean
                  because its geology, habitat and bird life bears greater
                  resemblance to South America than to other Caribbean islands.
                  It is home to around 220 species of bird.
                </p>
                <br />
                <p>
                  Tobago is also a small island which offers a great variety of
                  natural habitat for birds, including tropical rain forest,
                  mangrove swamp, open country and scrubland, gardens, parks and
                  coast in close proximity to each other.
                </p>
                <br />
                <p>
                  Arnos vale, the area in which Top O' Tobago is located, has
                  long been known for its birdwatching. Arnos vale is located
                  near the coast at the juncture of the hilly spine of the
                  island and the flatter coral regions to the south. See them up
                  close
                </p>
              </div>
            </div>
          </div>
          <div class="mt-12 sm:mt-16 lg:mt-0">
            <div
              class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full"
            >
              <img
                class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="https://res.cloudinary.com/millsit/image/upload/a_hflip/v1648401934/TOT/gallery-birds/Copper_rumped_hummingbird_rohsgq.jpg"
                alt="Bird"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <BlogListCards :posts="posts" />

    <div class="flex justify-center mt-4">
      <ImageGallery :imageUrls="galleryImageUrls" />
    </div>
    </DefaultLayout>
    
</template>
<script setup>
import BlogListCards from "@/components/BlogListCards.vue";
import ImageGallery from "@/components/ImageGallery.vue";

const posts = [
  {
    title: "See them up close",
    href: "#",
    description:
      "Some fly north from South America and others south from North America. Some live off the land, others live off the ocean.",
    imageUrl:
      "https://res.cloudinary.com/millsit/image/upload/v1649010489/TOT/birds/birds_upclose_xxncjm.jpg",
  },
  {
    title: "They are everywhere",
    href: "#",
    description:
      "They fly high overhead. They hover around the flowers in the gardens. They eat the mangoes and papayas in the orchards. They even come to visit in your dining patio.",
    imageUrl:
      "https://res.cloudinary.com/millsit/image/upload/v1649010489/TOT/birds/birds_everywhere_r1byqb.jpg",
  },
  {
    title: "Present in all shapes, sizes and colours",
    href: "#",
    description:
      "There are mot mots, hummingbirds, banaquits, blackbirds, blue-gray tanagers, green parrots, jacamars, doves, pygmy owls, frigate birds, and cocricos, to name just a few.",
    imageUrl:
      "https://res.cloudinary.com/millsit/image/upload/v1649010489/TOT/birds/birds_sizes_b3xst8.jpg",
  },
  {
    title: "They make themselves known",
    href: "#",
    description:
      "Their sounds urge you from bed in the mornings and accompany your pre-dinner drink in the late afternoon.",
    imageUrl:
      "https://res.cloudinary.com/millsit/image/upload/v1649010489/TOT/birds/birds_makeknown_dpl3us.jpg",
  },
  {
    title: "See them in action",
    href: "#",
    description:
      "Pecking, Diving, Building, Swallowing, Hovering, Gliding.",
    imageUrl:
      "https://res.cloudinary.com/millsit/image/upload/v1649010489/TOT/birds/birds_action_drxbll.jpg",
  },
];

const galleryImageUrls = [
  "https://res.cloudinary.com/millsit/image/upload/v1692968722/TOT/birds/Mot_Mot_igjsqh.jpg",
  "https://res.cloudinary.com/millsit/image/upload/v1692968722/TOT/birds/Hummingbird_in_flight_fg2pol.jpg",
  "https://res.cloudinary.com/millsit/image/upload/v1692968722/TOT/birds/Blue_Jays_mqs7zq.jpg",
  "https://res.cloudinary.com/millsit/image/upload/v1692968722/TOT/birds/Pelicans_kzphbk.jpg",
  "https://res.cloudinary.com/millsit/image/upload/v1692968722/TOT/birds/Woodpecker_cz6wll.jpg",
  "https://res.cloudinary.com/millsit/image/upload/v1692968722/TOT/birds/Hummingbird_qenqzs.jpg",
]
</script>

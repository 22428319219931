import type { RoomAvailability } from "../../api/_lib/availability";
import type { CalendarEvent } from "./calendar";
import { customAlphabet } from "nanoid";
import type { Booking, Guest, Pricing } from "../../../shared/src/types";

export const nanoid = customAlphabet("123456789ABCDEFGH", 6);

type BookingArg = { checkin: string; checkout: string };
/**
 *
 * @returns - list of calendar events of booked rooms
 */
export const getPublicBookingCalendarEvents = async ({
  checkin,
  checkout,
}: BookingArg): Promise<Array<CalendarEvent>> => {
  const response = await fetch("/api/availability", {
    method: "POST",
    body: JSON.stringify({ type: "calendar", checkin, checkout }),
  });
  const { data } = await response.json();
  return data;
};

export const getPublicFreeDates = async ({ checkin, checkout }: BookingArg): Promise<Array<RoomAvailability>> => {
  const response = await fetch("/api/availability", {
    method: "POST",
    body: JSON.stringify({ type: "free", checkin, checkout }),
  });
  const { data } = await response.json();
  return data;
};

/**
 * Get list of price periods
 * @param param0 
 */
export const getPriceList = async ({ checkin, checkout }: BookingArg): Promise<Array<Pricing>> => {
  const response = await fetch("/api/availability", {
    method: "POST",
    body: JSON.stringify({ type: "prices", checkin, checkout }),
  });
  const { data } = await response.json();
  return data;
};


export { RoomLabelMap } from "../../api/_lib/sanity";

export type { RoomAvailability } from "../../api/_lib/availability";

export const addBooking = async ({
  booking,
  guest,
}: {
  booking: Booking;
  guest: Guest | null;
}): Promise<Booking | null> => {
  const response = await fetch("/api/book", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ booking, guest }),
  });
  const res = await response.json();
  return res || null;
};

export const titleize = (str: string): string => {
  return str
      .toLowerCase()
      .replace(/(^|\s)\S/g, (match) => match.toUpperCase());
};

<template>
    <div>
    <div>
  <svg style="width: 0; height: 0;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <defs>
      <mask id="half">
        <rect x="0" y="0" width="32" height="32" fill="white" />
        <rect x="70%" y="0" width="32" height="32" fill="grey" />
      </mask>

      <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="star">
        <path d="M31.547 12a.848.848 0 00-.677-.577l-9.427-1.376-4.224-8.532a.847.847 0 00-1.516 0l-4.218 8.534-9.427 1.355a.847.847 0 00-.467 1.467l6.823 6.664-1.612 9.375a.847.847 0 001.23.893l8.428-4.434 8.432 4.432a.847.847 0 001.229-.894l-1.615-9.373 6.822-6.665a.845.845 0 00.214-.869z" />
      </symbol>
    </defs>
  </svg>
</div>

<p class="star-rating" aria-label="4.7 stars out of 5">
  <svg class="c-star active" width="32" height="32" viewBox="0 0 32 32">
    <use xlink:href="#star"></use>
    <use xlink:href="#star" fill="none" stroke="white"></use>
  </svg>
  <svg class="c-star active" width="32" height="32" viewBox="0 0 32 32">
    <use xlink:href="#star"></use>
    <use xlink:href="#star" fill="none" stroke="white"></use>
  </svg>
  <svg class="c-star active" width="32" height="32" viewBox="0 0 32 32">
    <use xlink:href="#star"></use>
    <use xlink:href="#star" fill="none" stroke="white"></use>
  </svg>
  <svg class="c-star active" width="32" height="32" viewBox="0 0 32 32">
    <use xlink:href="#star"></use>
    <use xlink:href="#star" fill="none" stroke="white"></use>
  </svg>
  <svg class="c-star active" width="32" height="32" viewBox="0 0 32 32">
    <use xlink:href="#star" mask=url(#half)></use>
    <use xlink:href="#star" fill="none" stroke="white"></use>
  </svg>
</p>
</div>
</template>
<style>
.star-rating {
  display: flex;
  justify-content: center;
  gap: 4px;
}

.c-star {
  width: var(--size, 32px);
  height: var(--size, 32px);
  fill: lightgrey;

  &.active {
    fill: #fed94b;
  }
}
</style>
export const BASE_URL_WEBSITE = (): string => {
  if (typeof window == "undefined") {
    return process.env.VERCEL_ENV == "production"
      ? "https://pay.topotobago.com"
      : process.env.VERCEL_ENV == "preview"
      ? "https://tot-payments-git-next-millsit.vercel.app"
      : "http://localhost:3000";
  } else {
    if (window.location.hostname.includes("localhost")) return "http://localhost:8081";
    else if (window.location.hostname.includes("tot-sanity-git-next-millsit"))
      return "https://tot-website-git-next-millsit.vercel.app";
    else if (window.location.hostname.includes("tot-sanity.vercel.app")) return "https://tot-website.vercel.app";
    else return "https://topotobago.com"; // assuming we are on the manager live prodcution site .. manager.topotobago.com
  }
};

export const BASE_URL_PAYMENT = (): string => {
  if (typeof window == "undefined") {
    return process.env.VERCEL_ENV == "production"
      ? "https://pay.topotobago.com"
      : process.env.VERCEL_ENV == "preview"
      ? "https://tot-payments-git-next-millsit.vercel.app"
      : "http://localhost:3000";
  } else {
    if (window.location.hostname.includes("localhost")) return "http://localhost:3000";
    else if (window.location.hostname.includes("tot-sanity-git-next-millsit"))
      return "https://tot-payments-git-next-millsit.vercel.app";
    else if (window.location.hostname.includes("tot-sanity.vercel.app")) return "https://tot-payments.vercel.app";
    else return "https://pay.topotobago.com"; // assuming we are on the manager live prodcution site .. manager.topotobago.com
  }
};

export const BASE_URL_SANITY = (): string => {
  return process.env.VERCEL_ENV == "production"
    ? "https://admin.topotobago.com"
    : process.env.VERCEL_ENV == "preview"
    ? "https://tot-sanity-git-next-millsit.vercel.app"
    : "http://localhost:3333";
};

export const POSTMARK_TOKEN = process.env.POSTMARK_TOKEN || "";
// export const rooms = [
//   {
//     id: 1,
//     name: "Main House",
//     category: "Main House",
//     type: "mainhouse",
//     href: "/accommodation/mainhouse",
//     price: "From $240",
//     imageSrc:
//       // "https://res.cloudinary.com/millsit/image/upload/v1648846219/TOT/accomodation/villa/room_villa_1_qqcenk.jpg",
//       "https://res.cloudinary.com/millsit/image/upload/v1648846220/TOT/accomodation/villa/room_villa_2_kv8r3x.jpg",
//     imageAlt:
//       "Luxurious three-roomed house opening on to a spectacular pool and ocean views, suitable for up to 5 people.",
//     stock: 1,
//   },
//   {
//     id: 2,
//     name: "Cabana Apartments",
//     category: "Cabanas",
//     type: "cabana",
//     href: "/accommodation/cabanas",
//     price: "From $120",
//     imageSrc:
//       "https://res.cloudinary.com/millsit/image/upload/v1648845543/TOT/accomodation/cabanas/room_cabana_1_zvftzt.jpg",
//     imageAlt: "Self-contained apartments perfect for 2 guests; suitable for 2 adults and 2 children.",
//     stock: 3,
//   },
// ];

import {TotServices} from ".";
import {toArray as _toArray} from "lodash";
import type {Pricing, RoomTypes} from "../../../shared/src/types";

const mainhouse_photo_names = [
  "mainhouse_trsatc",
  "Main_Living_Space_dt74sf",
  "Kitchen_cvepkn",
  "Office_Patio_dgwpdp",
  "Main_House_2nd_Bedroom_ylurmo",
  "Main_Bedroom_Shower_vsjdve",
  "Gazebo_Main_Bedroom_yngqhl",
  "View_from_the_Bed_in_Main_Bedroom_zgl9nw",
  "To_Second_Bedroom_yv0zas",
  "Sink_atxqyn",
  "Main_Bedroom_guckvx",
  "Buccoo_Reef_seas9v",
  "Hammock_bfmppl",
  "Living_Area_r0n4pl",
  "Office_modified_as_additional_sleeping_space_zdawx8",
];

const cabana_photo_names = [
  "Cabana_View_from_Rear_yfmq6y",
  "Cabana_Overview_n1pcdi",
  "Entrance_riigdp",
  "Sleep_Setup_for_4_tgtoei",
  "Patio_zr4lgh",
  "Kitchen_s7ut1p",
  "Cabana_kkcgzq",
];
const imageObjects = (items: any, folder: string) =>
  items.map((item: any, index: any) => {
    const name = item.split("_").slice(0, -1).join(" ");
    const src = `https://res.cloudinary.com/millsit/image/upload/c_scale,w_770/v1684214649/TOT/${folder}/${item}.jpg`;

    return {
      id: index + 1,
      name: name,
      src: src,
      alt: name,
    };
  });

const mainhouse_photos = imageObjects(mainhouse_photo_names, "accomodation/villa");
const cabana_photos = imageObjects(cabana_photo_names, "accomodation/cabanas");

export const roomDetails = {
  cabana: {
    name: "Cabana Apartments",
    price: "From $130",
    category: "Cabanas",
    type: "cabana",
    href: "/accommodation/cabanas",
    imageSrc:cabana_photos[0].src,
      //"https://res.cloudinary.com/millsit/image/upload/v1648845543/TOT/accomodation/cabanas/room_cabana_1_zvftzt.jpg",
      //"https://res.cloudinary.com/millsit/image/upload/c_scale,w_770/v1684214882/TOT/accomodation/cabanas/cabanas_spnyi1.jpg",
    imageAlt: "Self-contained apartments perfect for 2 guests; suitable for 2 adults and 2 children.",
    quantity: 3,
    images: cabana_photos,
    description: `
      <p>Self-contained apartments perfect for 2 guests; suitable for 2 adults and 2 children. There are 3 cabanas on the property</p>`,
    details: [
      {
        name: "Features",
        items: [
          "Add Hot and Cold Water",
          "High Speed Internet, Flat Screen TV, Access to Book Library",
          "Wireless Internet, TV and DVD player and movies to choose from;",
          "Outdoor gazebo patio with hammocks, dining table and chairs for dining in lovely gardens amongst hummingbirds;",
          "Access path to the beach and nature trails;",
        ],
      },
      ...TotServices,
      // More sections...
    ],
    bookingConditions: [
      "Cabanas sleep a maximum of 4.",
      "The price quoted includes 2 adults.",
      "Base occupancy is 2 adults, with up to 2 children under 10 y/o.",
      "Each adult or child over 10 y/o in addition to the base 2 person occupancy will be charged US$10 /night /person.",
      "Charges for additional occupancy will be collected upon check in.",
    ],
  },
  mainhouse: {
    name: "Main House",
    category: "Main House",
    price: "From $260",
    type: "mainhouse",
    href: "/accommodation/mainhouse",
    quantity: 1,
    imageSrc:
      // "https://res.cloudinary.com/millsit/image/upload/v1648846219/TOT/accomodation/villa/room_villa_1_qqcenk.jpg",
      //"https://res.cloudinary.com/millsit/image/upload/v1648846220/TOT/accomodation/villa/room_villa_2_kv8r3x.jpg",
      "https://res.cloudinary.com/millsit/image/upload/c_scale,w_770/v1684214649/TOT/accomodation/villa/mainhouse_trsatc.jpg",
    imageAlt:
      "Luxurious three-roomed house opening on to a spectacular pool and ocean views, suitable for up to 5 people.",

    images: mainhouse_photos,
    description: `
      <p>Luxurious three-roomed house opening on to a spectacular pool and ocean views, suitable for up to 5 people</p>`,
    details: [
      {
        name: "Features",
        items: [
          "Magnificent master bedroom with two private patios, spectacular mosaic vanity and outdoor shower for two",
          "Second bedroom with private entrance and full bath",
          "Private study with separate patio",
          "Comfortable living room with flat screen cable TV, music, games and books, high speed internet",
          "Large indoor dining area for ten people, outdoor dining for up to six people",
          "Large fully-equipped kitchen",
          "Surrounding patios with hammocks and loungers",
          "Excellent security with door bolts, attractive wrought iron on windows and safe for valuables",
        ],
      },
      ...TotServices,
      // More sections...
    ],
    bookingConditions: ["The main house sleeps a maximum of 6."],
  },
};

export const getRoomFeatures = (
  room: RoomTypes,
  allRooms?: typeof roomDetails | typeof roomDetails["cabana"]
): string[] => {
  // console.log(room);
  // if(isArray(roomDetails)) {
  //   return allRooms.find(r => r.type == room).detail[room].find((d: any) => d.name == "Features")?.items || [];
  // } else {
  return roomDetails[room].details.find((d: any) => d.name == "Features")?.items || [];
};

export const roomArray = _toArray(roomDetails);


export const getPricingByRoomType = async ( roomType: string ): Promise<Array<Pricing>> => {
  const response = await fetch(`/api/pricing?roomType=${roomType}`, { method: "GET" });
  return await response.json();
};
export const BASE_URL_PAYMENT =
  process.env.VERCEL_ENV == "production"
    ? "https://pay.topotobago.com"
    : process.env.VERCEL_ENV == "preview"
    ? "https://tot-payments-git-next-millsit.vercel.app"
    : "http://localhost:3000";

export const BASE_URL_SANITY =
  process.env.VERCEL_ENV == "production"
    ? "https://manager.topotobago.com"
    : process.env.VERCEL_ENV == "preview"
    ? "https://tot-sanity-git-next-millsit.vercel.app"
    : "http://localhost:3333";

export const SANITY_DATASET = process.env.SANITY_DATASET;
export const SANITY_SECRET = process.env.SANITY_SECRET;
